var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modalData.showModal)?_c('BaseModal',[_c('LocationModal',{attrs:{"headline":_vm.modalData.modalHeadline,"modalType":_vm.modalData.modalType,"location":_vm.modalData.existingItem,"locationId":_vm.modalData.existingItemId},on:{"close":function($event){_vm.modalData.showModal = false},"update":_vm.loadData}})],1):_vm._e(),_c('BaseTable',{staticClass:"mt-3",attrs:{"headers":_vm.mainheaders,"items":[_vm.area],"expanded":_vm.expandedItems,"hidePaginator":true},on:{"update:expanded":function($event){_vm.expandedItems=$event}},scopedSlots:_vm._u([(_vm.area)?{key:"title",fn:function(){return [_c('v-icon',{attrs:{"color":"primary","left":""},on:{"click":_vm.areasLink}},[_vm._v(" mdi-arrow-left")]),_c('h3',{staticClass:"title font-weight-light text-center",attrs:{"data-cy":"contract-lines-header"}},[_vm._v(" Adresse for "+_vm._s(_vm.area.place)+" ")]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary","max-width":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-message-question-outline ")])]}}],null,false,488187062)},[_c('span',[_vm._v("Her finner du og kan legge til/redigere adresseinformasjon på lokasjonen. Før du oppretter nye, sjekk om adresse er lagt inn fra før. I feltet \"Detaljer\" kan du gi ekstra informasjon som er relevant for lokasjonen")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Legg til adresse på lokasjon","icon":"mdi-plus"},on:{"handleClick":_vm.openNewLocation}})]},proxy:true}:null,{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('BaseTable',{attrs:{"headers":_vm.subheaders,"items":_vm.locations,"search":_vm.search},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Aktiv" : "Inaktiv")+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.displayLocation(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("Vis rom ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.openEditLocation(item, item.street)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger rom ")])])]}}],null,true)})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }